<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_patient") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="grid-content">
      <div class="add_patient">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          class="twoform"
          v-loading="loadingButton"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item :label="columns.first_name.title" prop="first_name">
                <el-input
                  v-model="form.first_name"
                  :placeholder="columns.first_name.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.surname.title" prop="surname">
                <el-input
                  v-model="form.surname"
                  :placeholder="columns.surname.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.patronymic.title" prop="patronymic">
                <el-input
                  v-model="form.patronymic"
                  :placeholder="columns.patronymic.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <!-- <el-form-item :label="columns.phone.title">
                <el-input
                  v-model="form.phone"
                  :placeholder="columns.phone.title"
                ></el-input>
              </el-form-item> -->
              <label class="el-form-item__label w-100 d-flex" style=" float: none;">{{columns.phone.title}}</label>
              <div class="w-100 mb-4">
                <phone-number-input v-model="form.phone" :old="form.phone" :country_code="form.country_code" @getPhoneStatus="getPhoneStatus" size="md"/>
                 <!-- <vue-tel-input v-model="form.phone" size="lg"></vue-tel-input> -->
              </div>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.born_date.title">
                <el-date-picker
                  type="date"
                  v-model="form.born_date"
                  :placeholder="columns.born_date.title"
                  style="width: 100%"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.drive_license.title">
                <crm-input
                  :placeholder="'AA1111010'"
                  :maskFormat="'passport'"
                  :inputValue="form.passport_number"
                  v-model="form.passport_number"
                  :size="'medium'"
                ></crm-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.drive_license.title">
                <crm-input
                  :placeholder="'AA1111010'"
                  :maskFormat="'passport'"
                  :inputValue="form.drive_license"
                  v-model="form.drive_license"
                  :size="'medium'"
                ></crm-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.social_id.title" prop="social_id">
                <el-select
                  v-model="form.social_id"
                  :placeholder="columns.social_id.title"
                >
                  <el-option
                    v-for="item in socials"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.code.title" prop="code">
                <el-input
                  v-model="form.code"
                  :placeholder="columns.code.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div class="pol_radio">
                <el-form-item :label="columns.gender.title" prop="gender">
                  <el-radio-group v-model="form.gender">
                    <el-radio label="man" border size="medium">{{
                      $t("message.male")
                    }}</el-radio>
                    <el-radio label="woman" border size="medium">{{
                      $t("message.female")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content mb20">
                <el-tabs
                  v-model="activetab"
                  class="ifoData mb20"
                  type="border-card"
                >
                  <el-tab-pane
                    :label="$t('message.address_register')"
                    name="first"
                  >
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item
                          :label="$t('message.country')"
                          prop="state_id"
                        >
                          <states
                            v-model="form.state_id"
                            :id="model.state_id"
                            :state_id="form.state_id"
                          ></states>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.city')">
                          <cities
                            v-model="form.city_id"
                            :id="model.city_id"
                            :state_id="form.state_id"
                          ></cities>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.region')">
                          <regions
                            v-model="form.region_id"
                            :id="model.region_id"
                            :region_id="form.region_id"
                            :city_id="form.city_id"
                          ></regions>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.street')">
                          <el-input
                            v-model="form.street"
                            :placeholder="$t('message.street')"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <!-- <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.district')">
                          <el-input
                            v-model="form.district"
                            :placeholder="$t('message.district')"
                          ></el-input>
                        </el-form-item>
                      </el-col> -->

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.house')">
                          <el-input
                            v-model="form.home"
                            :placeholder="$t('message.house')"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.flat')">
                          <el-input
                            v-model="form.flat"
                            :placeholder="$t('message.flat')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                 
                </el-tabs>
              </div>
            </el-col>

            <el-col :span="24" class="mt-3">
              <el-checkbox-group v-model="form.tags" size="mini">
                <el-checkbox-button
                  v-for="tag in tags"
                  :key="tag.id"
                  :label="tag.id"
                  >{{ tag.name }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import cities from "@/components/selects/city";
import regions from "@/components/selects/region";
import states from "@/components/selects/state";
import form from "@/utils/mixins/form";
import phoneNumberInput from '@/components/crm/include/crm-phone-number-input';
import _ from 'lodash';
export default {
  mixins: [form],
  components: {
    regions,
    cities,
    states,
    phoneNumberInput
  },
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      activetab: "first",
      is_phone_valid: false,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {
    if (this.socials && this.socials.length === 0) await this.loadSocials();
    if (this.tags && this.tags.length === 0) await this.loadTags();
    if (this.cities && this.cities.length === 0) await this.loadCities();
    if (this.states && this.states.length === 0) await this.loadStates();
    if (this.document_types && this.document_types.length === 0)
      await this.loadDocumentTypes();
    if (this.list_organization && this.list_organization.length === 0)
      await this.loadOrganization();
  },
  computed: {
    ...mapGetters({
      socials: "socials/list",
      tags: "tags/list",
      model: "patients/model",
      patient_last_id: "patients/last_model_id",
      document_types: "documentTypes/list",
      rules: "patients/rules",
      columns: "patients/columns",
      states: "states/list",
      cities: "cities/list",
      list_organization: "organizations/list",
    }),
    citiesFunc: function () {
      return this.states.filter((item) => {
        return item.id == this.form.state_id;
      });
    },
  },
  methods: {
    ...mapActions({
      save: "patients/update",
      loadSocials: "socials/index",
      loadTags: "tags/index",
      loadOrganization: "organizations/index",
      editModel: "patients/show",
      empty: "patients/empty",
      loadDocumentTypes: "documentTypes/index",
      loadStates: "states/index",
      loadCities: "cities/index",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!_.size(this.form.phone)) {
            this.is_phone_valid = true;
            this.form.phone = null;
          }
          if (this.is_phone_valid) {
            this.loadingButton = true;
            if (this.form.phone) {
              for (let key in this.form) {
                if (key == 'phone' && this.form[key]) {
                    this.form[key] = this.form[key].substring(1);
                }
            }
            }
            this.save(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.empty();
                this.parent().listChanged();
                this.resetForm("form");
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          }else{
            this.$notify({
                  title: this.$t('message.validation'),
                  type: "error",
                  offset: 130,
                  message: this.$t('message.phone_number_is_not_valid')
              });
          }
        } 
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("after-close");
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
      getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.country_code = countryCode;
    },
  },
};
</script>
